.container {
  @apply flex items-center lg:hidden;
}

.displayFlex {
  @apply flex;
}

.button {
  @apply -ml-2 rounded-md py-2 pl-2 pr-3.5 opacity-100 hover:opacity-80;
}

.srOnly {
  @apply sr-only;
}

.icon {
  @apply h-6 w-6;
}
