.processingContainer {
  @apply absolute flex h-[170px] justify-center content-center items-center z-30 top-[50%] w-full;
}

.processingContainer {
  @apply fixed opacity-100;
}

.processingSpinner {
  @apply fixed;
}

.processingText {
  @apply font-medium;
}
