.dialogMain {
  @apply relative z-40;
}

.dialogContent {
  @apply fixed inset-0 z-40 flex;
}

.dialogPanel {
  @apply fixed inset-0 flex w-full max-w-xs flex-col bg-white shadow-xl;
}

.dialogPanelClose {
  @apply flex px-2 pb-2 pt-5;
}

.iconSize {
  @apply h-6 w-6;
}

.closePanelButton {
  @apply inline-flex items-center justify-center rounded-md p-2 text-gray-400;
}

.navMain {
  @apply flex-1 overflow-y-auto border-t border-spring-neutral-20 pt-5 pb-20 py-5;
}

.collectionLink {
  @apply block p-2 font-medium;
}

.collectionContent {
  @apply text-sm leading-6 border-b border-spring-neutral-20 px-2 py-2;
}

.collectionDefault {
  @apply whitespace-nowrap;
}

.subCollectionDefault {
  @apply block rounded-md p-2 font-light;
}

.socialMediaIconsContainer {
  @apply h-12 pt-10 items-center flex;
}

.socialMediaLinks {
  @apply text-gray-400 hover:text-gray-500 pl-4;
}

.active {
  @apply font-medium;
}
