.container {
  @apply flex flex-row h-14 items-center content-center align-middle space-x-1;
}

.promoCodeButton {
  @apply border-solid border rounded px-1 mx-1;
}

.hideInMobile {
  @apply hidden sm:flex;
}
