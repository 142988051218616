.componentWrapper {
  @apply hidden h-full lg:flex lg:justify-center;
}

.componentHidden {
  @apply invisible;
}

.navTopHidden {
  @apply hidden;
}

.navContentContainer {
  @apply flex h-full justify-center space-x-8 items-center;
}

.collectionActiveClass {
  @apply border-gray-900 opacity-100;
}

.collectionInActiveClass {
  @apply border-transparent;
}

.collectionDefaultClass {
  @apply relative h-16;
}

.addBorder {
  @apply border-b-2;
}

.collectionLinkClass {
  @apply inline-flex items-center whitespace-nowrap px-1 py-4 font-medium opacity-100 hover:opacity-80;
}

.subCollectionClass {
  @apply absolute hidden flex-col w-max-content top-16 bg-white z-10 rounded shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.subCollectionDefaultClass {
  @apply py-2 pl-4 pr-12 text-spring-neutral-100 font-light;
}

.subCollectionActiveClass {
  @apply font-medium;
}
