.paginationContainer {
  @apply mx-auto max-w-2xl px-4 py-9 lg:max-w-7xl lg:px-8 text-spring-text-dark;
}

.paginationNav {
  @apply flex items-center justify-between border-t border-spring-light-gray px-0;
}

.paginationPrev,
.paginationNext {
  @apply -mt-px flex w-0 flex-1;
}

.paginationNext {
  @apply justify-end;
}

.paginationBtn {
  @apply inline-flex items-center border-t-2 border-transparent pt-4 text-sm font-medium text-spring-text-dark hover:border-spring-light-gray;
}

.paginationBtn:disabled {
  @apply cursor-not-allowed text-spring-disable-gray border-t-0;
}

.paginationBtn:disabled svg {
  @apply text-spring-disable-gray;
}

.svgIconNext {
  @apply ml-3 h-5 w-5 text-spring-text-dark;
}

.svgIconPrevious {
  @apply mr-3 h-5 w-5 text-spring-text-dark;
}

.paginationPagesContainer {
  @apply flex justify-center md:-mt-px overflow-x-hidden w-9/12;
}

.paginationPagesButtonActive {
  @apply inline-flex items-center border-t-2 border-primary-button-background px-4 pt-4 text-sm font-medium text-primary-button-background pointer-events-none;
}

.paginationPagesButton,
.threeDots {
  @apply inline-flex items-center px-4 pt-4 text-sm font-medium text-spring-text-dark;
}

.paginationPagesButton {
  @apply border-t-2 border-transparent hover:border-spring-light-gray;
}
