.heroBannerContainerBasic {
  @apply grid relative w-full min-h-0;
  padding-bottom: 36.36%;
}

.heroBannerContainer {
  @apply grid relative w-full min-h-[500px] lg:min-h-[600px];
}

.heroBannerImage {
  @apply object-cover object-center w-full;
}
