.noticeContainer {
  @apply relative mt-3 flex flex-1 items-center justify-center py-4 space-x-3 text-gray-600 sm:w-full;
}

.noticeText {
  @apply space-x-2 text-sm;
}

.learnMoreLink {
  @apply relative underline text-spring-neutral-70 cursor-pointer;
}

.viewLink {
  @apply underline text-spring-neutral-70 cursor-pointer;
}

.helperText {
  @apply absolute left-0 right-0 m-auto z-10 w-[330px] h-[250px] border border-spring-neutral-20 bg-white px-6 py-4 shadow-md rounded;
}

.helpTextHeader {
  @apply text-spring-neutral-100 font-medium text-lg leading-7 mb-4;
}

.helperTextList {
  @apply font-medium text-sm leading-5;
}

.listItem {
  @apply py-1 text-spring-neutral-70;
}
