.productTile {
  @apply flex flex-col items-end;
}

.productImageContainer {
  @apply relative aspect-h-1 aspect-w-1 overflow-hidden rounded-md border border-spring-neutral-20;
  width: 100%;
  max-height: 300px;
}

.productImage {
  @apply h-full w-full object-contain object-center;
}

.hiddenProductName {
  @apply relative inset-0;
}

.productPrice {
  @apply text-sm pt-1 font-bold text-left lg:pt-0 md:pt-0 lg:text-right md:text-right;
}

.productInfoContainer {
  @apply mt-4 w-full flex flex-col text-black lg:flex-row md:flex-row lg:justify-between md:justify-between;
  font-family: 'Inter', sans-serif;
}

.productContent {
  @apply text-sm font-normal text-left mr-1;
}

.productName {
  @apply block text-sm font-normal text-spring-neutral-70;
}

.productTypeClass {
  @apply pt-1 text-sm text-spring-neutral-30;
  font-family: 'Inter', sans-serif;
}
