.heroBannerContentContainer {
  @apply mx-auto flex flex-col justify-center w-full h-full items-center;
}

.herBannerContent {
  @apply max-w-[50rem] text-center z-10;
}

.heroBannerSubtitle {
  @apply text-lg;
}

.heroBannerTitle {
  @apply mb-4;
}

.heroBannerText {
  @apply text-base mb-2;
}

.herBannerLink {
  @apply inline-block rounded-md border border-transparent mt-6 px-5 py-2 text-sm cursor-pointer hover:opacity-70;
}

.anchor {
  @apply h-0 overflow-hidden relative -top-36;
}
