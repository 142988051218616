.footerHeading {
  @apply sr-only;
}

.aboutText {
  @apply text-center m-auto max-w-[520px] mb-10 text-sm leading-6;
}

.footerContainer {
  @apply px-6 py-[29px] lg:mx-auto lg:max-w-[1600px] lg:pt-[75px] pt-[70px] mb-24 lg:mb-0 md:mb-0;
  font-family: 'Inter', sans-serif;
}

.footerLogo {
  @apply flex justify-center w-full mb-6;
}

.footerLogo img {
  @apply object-center;
}

.footerInnerContainer {
  @apply mx-auto max-w-7xl overflow-hidden px-6 pb-20 sm:pb-24 lg:px-8;
}

.footerNav {
  @apply -mb-6 lg:columns-2 sm:flex sm:justify-center sm:space-x-12;
}

.footerItem {
  @apply pb-6 text-center;
}

.footerLink {
  @apply text-sm leading-6 opacity-100 hover:opacity-80;
}

.socialMediaContainer {
  @apply mt-10 flex justify-center space-x-10;
}

.socialMediaLink {
  @apply opacity-100 hover:opacity-80;
}

.socialMediaIcon {
  @apply h-6 w-6;
}

.poweredByText {
  @apply flex justify-center mt-10 text-center text-sm leading-6 underline;
}

.poweredByLink {
  @apply opacity-100 hover:opacity-80 flex;
}

.footerBottomContainer {
  @apply flex flex-col items-center justify-between pt-4 lg:mx-auto lg:max-w-7xl lg:flex-row border-t border-opacity-20;
}

.footerBottomItem {
  @apply my-6 flex items-center lg:my-0;
}

.regionCurrency {
  @apply p-1;
}

.footerList {
  @apply flex items-center;
}

.footerListItem {
  @apply mx-4 text-sm lg:ml-8 opacity-100 hover:opacity-80;
}

.springLogoImage {
  @apply object-contain object-center self-center w-full;
}

.springLogoContainer {
  width: 90px;
  margin-left: 0.8rem;
}
