.storeLogoDiv {
  @apply h-16 lg:contents items-center w-full lg:w-0 flex;
}

.storeLogoContainer {
  @apply relative w-32 h-11;
}

.storeLogoImage {
  @apply object-contain self-center w-full object-left;
}
