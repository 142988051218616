.cartComponent {
  @apply ml-4 flow-root;
}

.cartLink {
  @apply flex items-center pl-2 py-2 opacity-100 hover:opacity-80;
}

.shoppingBagIcon {
  @apply h-6 w-6 flex-shrink-0;
}

.cartQuantityText {
  @apply ml-2 text-sm font-medium;
}

.srOnly {
  @apply sr-only;
}
