.navMain,
.defaultNavMain {
  @apply flex mx-auto px-4 pt-7 max-w-2xl lg:max-w-7xl;
}

.defaultNavMain {
  @apply lg:hidden;
}

.navContent {
  @apply flex overflow-x-auto;
}

.collectionActive {
  @apply font-medium ring-gray-700 bg-gray-50;
}

.collectionDefault {
  @apply relative whitespace-nowrap border-spring-neutral-20 rounded-lg mr-4 px-3.5 py-2.5 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}
