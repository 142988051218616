.deliveryDetails,
.returnDetails {
  @apply py-1 font-medium text-gray-900;
}

.deliveryList,
.returnList {
  @apply mb-4 ml-2;
}

.deliveryList li,
.returnList li {
  @apply px-2;
}

.instantDownload {
  @apply mb-4;
}
