.container {
  @apply mb-2;
}

.paragraph {
  @apply py-4;
}

.link {
  @apply text-sm hover:text-gray-900;
}
