.unitButton {
  @apply px-4 py-2 border-b-2;
}

.unitButtonActive {
  @apply border-black;
}

.unitButtonInactive {
  @apply border-transparent;
}

.tableContainer {
  @apply max-h-[90vh] overflow-auto;
}

.flexContainer {
  @apply flex justify-start space-x-4;
}

.tableGrid {
  @apply mt-4 border border-spring-neutral-20 grid;
}

.girdCols-2 {
  @apply grid-cols-2;
}

.gridCols-3 {
  @apply grid-cols-3;
}

.gridCols-4 {
  @apply grid-cols-4;
}

.girdCols-5 {
  @apply grid-cols-5;
}

.tableCell {
  @apply font-bold border border-spring-neutral-20 p-2;
}

.tableCellStriped {
  @apply bg-gray-100;
}
