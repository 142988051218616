.mainContainer {
  @apply text-gray-700 mx-auto w-full border-t border-spring-neutral-20 text-sm;
}

.infoButton {
  @apply flex w-full items-center justify-between text-left h-[20px] py-2.5 font-medium text-gray-900;
}

.infoContainer {
  @apply border-b border-spring-neutral-20 py-6;
}

.infoPanel {
  @apply pt-3;
}

.panelPreview {
  @apply mt-3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon {
  @apply block h-4 w-4 text-gray-400 hover:text-gray-500;
}

.iconContainer {
  @apply ml-6 flex items-center;
}

.subHeading {
  @apply text-lg;
}

.list {
  list-style-type: disc;
  list-style-position: inside;
}
