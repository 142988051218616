.colorBlock {
  @apply block;
}

.colorClass {
  @apply flex cursor-pointer items-center justify-center rounded-full p-2 h-6 w-6 border border-spring-neutral-20;
}

.selectedColor {
  @apply ring ring-offset-1 ring-primary-button-background;
}

.colorsContainer {
  @apply inline-flex flex-wrap content-center items-center gap-2;
}

.colorLabel {
  @apply text-sm text-zinc-400 mb-2;
}
