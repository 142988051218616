.headerMainContainer {
  @apply sticky top-0 z-20 w-full h-auto;
}

.headerContainer {
  @apply flex items-center justify-between border-b border-spring-neutral-20 shadow;
}

.headerContent {
  @apply relative flex flex-col md:flex-row justify-center items-center w-full;
}

.headerMain {
  @apply flex h-16 px-4 items-center w-full mx-auto justify-between lg:max-w-7xl lg:px-8;
}

.headerEnd {
  @apply flex items-center justify-end;
}

.storeLogoContent {
  @apply w-full flex;
}
