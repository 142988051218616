.inputRow,
.resultContainer,
.horizontalRule {
  @apply mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8;
}

.searchButton {
  @apply ml-4 p-2 opacity-100 hover:opacity-80;
}

.overlayContent {
  @apply fixed inset-0 cursor-default z-50 bg-white h-fit max-h-full overflow-y-auto lg:px-8;
}

.inputRow {
  @apply flex flex-row items-center w-full h-16 justify-between p-4 lg:px-0;
}

.searchInput {
  @apply w-full p-2;
}

.resultContainer {
  @apply text-start items-start w-full justify-between p-10 lg:px-0;
}

.iconStyle {
  @apply h-6 w-6;
}

.headerContainer {
  @apply mb-5;
}
