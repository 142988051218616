.productContainer {
  @apply lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-8 lg:pt-10;
}

.imageContainer {
  @apply col-span-6 lg:col-span-7 lg:px-5 py-0;
}

.imageDisplay {
  @apply flex flex-col-reverse;
}

.productInfo {
  @apply mt-10 lg:mt-0 px-6 md:px-10 lg:py-0 md:py-0 lg:px-8 col-span-6 lg:col-span-5;
}

.productHeader {
  @apply lg:flex;
}

.mobileHide {
  @apply hidden lg:flex;
}

.productOptions {
  @apply mt-6;
}

.addToCart {
  @apply mt-10 flex;
}

.additionalDetails {
  @apply mt-2;
}

.sectionSpacing {
  @apply py-5;
}

.addToCartSticky {
  @apply w-full bg-white fixed bottom-0 left-0 p-3 z-20;
  box-shadow: 0px -3px 20px 0px rgba(0, 0, 0, 0.14);
}

.addToCartSticky button {
  @apply w-full;
}
