.loadingIcon {
  @apply inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite];
}

.loadingIconStatus {
  @apply !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)];
}

button.available {
  @apply flex flex-1 items-center justify-center rounded-xl px-8 py-3 text-base font-medium text-primary-text-color border border-primary-button-border hover:brightness-[0.85] focus:outline-none focus:ring-2 focus:ring-primary-button-background focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full bg-primary-button-background cursor-pointer;
}

button.availableLoading {
  @apply flex flex-1 items-center justify-center rounded-xl px-8 py-3 text-base font-medium text-primary-text-color border border-primary-button-border hover:brightness-[0.85] focus:outline-none focus:ring-2 focus:ring-primary-button-background focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full bg-primary-button-background cursor-wait;
}

button.notAvailable {
  @apply flex flex-1 items-center justify-center rounded-xl px-8 py-3 text-base font-medium text-primary-text-color border border-primary-button-border hover:brightness-[0.85] focus:outline-none focus:ring-2 focus:ring-primary-button-background focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full bg-gray-300 pointer-events-none;
}
