.reportContainer {
  @apply flex justify-center my-6;
}

.reportLink {
  @apply flex items-center;
}

.icon {
  @apply h-3.5 mr-1;
}
