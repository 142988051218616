.mainContainer {
  @apply mx-auto max-w-2xl px-4 mt-7 lg:max-w-7xl lg:px-8;
}

.mainContainerFull {
  @apply mx-auto max-w-2xl px-4 pt-7 lg:w-full lg:max-w-full lg:px-7;
}

.collectionsPage {
  @apply pb-14;
}

.tilesPerRow3 {
  @apply lg:grid lg:grid-cols-3 lg:gap-x-7 lg:gap-y-8;
}

.tilesPerRow2 {
  @apply lg:grid lg:grid-cols-2 lg:gap-x-7 lg:gap-y-8;
}

.mainContainer > div,
.mainConatinerFull > div {
  @apply mb-6 lg:mb-0;
}
