.backdrop {
  @apply fixed inset-0 bg-black/30 z-30;
}

.dialogContainer {
  @apply fixed inset-0 flex m-auto items-center justify-center z-30;
}

.dialogPanel {
  @apply relative bg-white p-10 w-full md:w-3/4 max-w-[500px] min-h-[200px] rounded-lg shadow-md;
}

.closeButton {
  @apply absolute right-2 top-2;
}

.dialogTitle {
  @apply text-3xl mb-4;
}

.sizeGuideButton {
  @apply flex space-x-1 text-sm font-medium text-zinc-400 hover:text-gray-400 mr-4;
}
