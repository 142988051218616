.mainContainer {
  @apply mx-auto max-w-2xl px-4 py-7 lg:max-w-7xl lg:px-8;
}

.headerContainer {
  @apply col-start-1 row-start-1 flex items-center justify-between;
}

.productGrid {
  @apply mt-4 lg:mt-6 grid grid-cols-2 gap-x-5 gap-y-6 lg:grid-cols-4 lg:gap-x-7 lg:gap-y-8;
}

.productsDefaultContainer {
  @apply relative;
}
