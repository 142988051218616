.container {
  @apply relative z-50;
}

.closeIcon {
  @apply h-6 w-6 font-light opacity-80 hover:opacity-100;
}

.dialogContainer {
  @apply fixed inset-0 z-30 overflow-y-auto;
}

.dialog {
  @apply flex lg:min-h-full md:min-h-full justify-center text-center md:items-center md:px-2 lg:px-4 absolute bottom-0 w-full;
}

.dialogPanel {
  @apply relative transform lg:rounded-lg md:rounded-lg lg:max-w-[350px] md:max-w-[350px] w-full bg-white text-center shadow-xl transition-all opacity-100;
}

.titleBar {
  @apply flex justify-end w-full border-b border-spring-neutral-20 p-5;
}

.title {
  @apply text-lg font-medium leading-6 text-gray-900 w-full;
}

button.closeButton {
  @apply rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none;
}

.form {
  @apply bg-white rounded items-center px-8 pt-6 mb-7;
}

.formHeader {
  @apply text-gray-900;
}

.labelContainer {
  @apply flex justify-start my-5 text-left items-center text-sm text-gray-900;
}

.label {
  @apply flex;
}

.labelText {
  @apply ml-2;
}

.buttonContainer {
  @apply flex justify-between mt-6;
}

button.cancelButton {
  @apply flex mr-2 w-full h-12 justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
}

button.submitButton {
  @apply ml-2 w-full h-12 justify-center items-center rounded-md border border-primary-button-border bg-primary-button-background disabled:bg-gray-500 disabled:cursor-not-allowed px-3 py-2 text-sm font-semibold text-white hover:brightness-[0.85] focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-button-background !important;
}
