.productGrid {
  @apply mt-4 lg:mt-6 grid grid-cols-2 gap-x-5 gap-y-6 lg:grid-cols-4 lg:gap-x-7 lg:gap-y-8;
}

.mainContainer {
  @apply mx-auto pb-10;
}

.headerContainer {
  @apply col-start-1 row-start-1 flex items-center justify-between;
}
