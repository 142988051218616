.fixedInsetFlex {
  @apply fixed inset-0 z-50 overflow-y-auto;
}

.flexCenter {
  @apply flex md:min-h-full items-center justify-center mb-24 lg:mb-0;
}

.dialogPanelStyle {
  @apply relative w-full lg:max-w-[1023px] flex-col bg-white shadow-xl rounded-md top-[21px] flex m-auto items-center justify-center;
}

button.buttonStyle {
  @apply flex items-center justify-center rounded-2xl text-gray-500 h-10 w-10 z-20 absolute top-4 md:top-3 right-4 md:right-3 border border-gray-200 bg-white;
}

.iconStyle {
  @apply w-6 h-6;
}

.productContainer {
  @apply w-full lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-8 lg:p-10 mt-0 relative;
}
